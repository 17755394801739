import {
  AGENDA_MESSAGE,
  CREATE_AGENDA_SUCCESS,
  DELETE_AGENDA_SUCCESS,
  GET_BY_PROJECT_AGENDA_SUCCESS,
  GET_BY_USER_AGENDA_SUCCESS,
  UPDATE_AGENDA_SUCCESS,
} from 'redux/constants/manage-project/agenda.type';

import {  notification } from 'antd';
import { combineDateAndTime, millisToMinutesAndSeconds } from 'utils/functions';
import { 
	CalendarFilled
} from '@ant-design/icons';

const initialState = {
  agendaData: [],
  loading: false,
  message: ''
};

function AgendaReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BY_PROJECT_AGENDA_SUCCESS:
      return { ...state, loading: false, agendaData: action.payload };
    case CREATE_AGENDA_SUCCESS:
      return { ...state, agendaData: [...state.agendaData, action.payload] };
    case UPDATE_AGENDA_SUCCESS:
      const updated = state.agendaData.map((l) =>
        l._id === action.payload._id ? action.payload : l
      );
      return { ...state, loading: false, agendaData: updated };
    case DELETE_AGENDA_SUCCESS:
      const deleted = state.agendaData.filter(
        (l) => l._id !== action.payload._id
      );
      return { ...state, loading: false, agendaData: deleted };

    case AGENDA_MESSAGE:
      return {
        ...state,
        message: action.message,
        loading: false,
      };

    case GET_BY_USER_AGENDA_SUCCESS:
      let {agendas} = action.payload
      const currentDate = new Date();
      // REQUEST NOTIFICATIONS PERMISSIONS
      if (!("Notification" in window)) {
        console.log("Browser does not support desktop notification");
      } else {
        Notification.requestPermission();
      }
      
      agendas.forEach(a => {
        const aDate = combineDateAndTime(a.date, a.start);
        const diff = aDate-currentDate;

        const body = `Vous aurez un evenement en moins dans ${millisToMinutesAndSeconds(diff)}.`;
        const title = a.title;

        let options = {
          body: body,
          icon: 'https://icons.iconarchive.com/icons/dtafalonso/android-lollipop/512/calendar-icon.png',
          dir: 'ltr',
        };
        if(diff > 0 && diff < 1800000) {
          if(diff <= 300000) {
            const key = `open${Date.now()}`;
            notification.open({
                message: title,
                description: body,
                key,
                icon: <CalendarFilled style={{color: '#dc3545'}} />,
                placement: "bottomRight",
                duration: 20
            });
            const notif = document.getElementById('notif-event-mp3');
            notif.play();
            new Notification(title, options);
          } else if (diff <= 900000) {
            const key = `open${Date.now()}`;
            notification.open({
                message: title,
                description: body,
                key,
                icon: <CalendarFilled style={{color: '#ffc107'}} />,
                placement: "bottomRight",
                duration: 20
            });
            
            new Notification(title, options);
          } else if (diff <= 1800000) {
            const key = `open${Date.now()}`;
            notification.open({
                message: title,
                description: body,
                key,
                icon: <CalendarFilled style={{color: '#0dcaf0'}} />,
                placement: "bottomRight",
                duration: 20
            });
            new Notification(title, options);
          }
        }
      });
      
      return state

    default:
      return state;
  }
}

export default AgendaReducer;
