import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { THEME_CONFIG } from './configs/AppConfig';
import {
  chatSocket,
  socket,
  SocketContext,
  SocketContextChat,
  SocketContextTracking,
  SocketTimerContext,
  timerSocket,
  trackingSocket,
} from 'utils/socket';

import notifMp3 from 'assets/audio/notifications-sound-127856.mp3';
import eventMp3 from 'assets/audio/second-hand-149907.mp3';
import DesktopAppProvider from 'providers/DesktopAppProvider';
import RouteProvider from 'providers/RouteProvider';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  return (
    <div className="App">
      <SocketContext.Provider value={socket}>
        <SocketContextChat.Provider value={chatSocket}>
          {/* <SocketContextTracking.Provider value={trackingSocket}> */}
          <SocketTimerContext.Provider value={timerSocket}>
            <Provider store={store}>
              <ThemeSwitcherProvider
                themeMap={themes}
                defaultTheme={THEME_CONFIG.currentTheme}
                insertionPoint="styles-insertion-point"
              >
                <RouteProvider>
                  <DesktopAppProvider>
                    <Router>
                      <Switch>
                        <Route path="/" component={Views} />
                      </Switch>
                    </Router>
                  </DesktopAppProvider>
                </RouteProvider>
              </ThemeSwitcherProvider>
            </Provider>
          </SocketTimerContext.Provider>
          {/* </SocketContextTracking.Provider> */}
        </SocketContextChat.Provider>
      </SocketContext.Provider>
      <audio id="notif-message-mp3">
        <source src={notifMp3} type="audio/mpeg" />
      </audio>
      <audio id="notif-event-mp3">
        <source src={eventMp3} type="audio/mpeg" />
      </audio>
    </div>
  );
}

export default App;
