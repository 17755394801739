import React, { Component } from 'react';
import { Menu, Dropdown, Badge, Avatar, List, Tooltip } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import Flex from 'components/shared-components/Flex';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ignoreAllNotification, ignoreOneNotification } from 'redux/actions/mail/mail.action';

class NavMail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            notifications: []
        };
    }

    navigateRequest = (id) => {
        const { history, inboxName, ignoreOneNotification } = this.props;
        ignoreOneNotification(id);
        history.push(`/workspace/apps/mail/${inboxName}/${id}`);
    }

    handleVisibleChange = (flag) => {
        this.setState({ visible: flag });
    }

    getNotificationBody = (list) => {
        return list.length > 0 ? (
            <List
                size="small"
                itemLayout="horizontal"
                dataSource={list}
                renderItem={(item) => (
                    <List.Item onClick={() => this.navigateRequest(item.key)} className="list-clickable">
                        <Flex alignItems="center">
                            <div className="pr-3">
                                <Tooltip title={`From: ${item.email}`}>
                                    <Avatar style={{ backgroundColor: "#007bff", color: "white", padding: '1.5em', paddingTop: '0' }} size="30">
                                        @{item.email.charAt(0).toUpperCase()}
                                    </Avatar>
                                </Tooltip>
                            </div>
                            <div className="mr-3">
                                <Tooltip title={`From: ${item.email}`}>
                                    <span className="font-weight-bold text-dark">
                                        {item.name}
                                    </span>
                                </Tooltip>
                                <span className="text-gray-light ml-1">
                                    send you an email.
                                </span>
                                <span className="font-weight-bold text-dark">
                                    "{item.subject}"
                                </span>
                            </div>
                            <small className="ml-auto">
                                {item.date}
                            </small>
                        </Flex>
                    </List.Item>
                )}
            />
        ) : (
            <div className="empty-notification">
                <img src="/img/others/img-10.png" alt="Empty" />
                <p className="mt-3">Empty</p>
            </div>
        );
    }

    componentDidMount() {
        const {notifications} = this.props;
        this.setState({notifications: notifications});
    }

    componentDidUpdate(prevProps) {
        const {notifications} = this.props;
        if(prevProps.notifications !== notifications && notifications) {
            this.setState({notifications: notifications});
        }
    }

    ignoreAll = () => {
        const {ignoreAllNotification} = this.props;
        ignoreAllNotification();
    }

    render() {
        const { visible, notifications } = this.state;

        const notificationList = (
            <div className="nav-dropdown nav-notification" style={{ width: '30em', left: '11vw', position: 'relative' }}>
                <div className="nav-notification-header d-flex justify-content-between align-items-center">
                    <h4 className="mb-0">New emails</h4>
                    <h4 onClick={this.ignoreAll} className='mb-0' style={{ color: 'rgb(255, 123, 0)', cursor: 'pointer' }}>Ignore all</h4>
                </div>
                <div className="nav-notification-body">
                    {this.getNotificationBody(notifications)}
                </div>
            </div>
        );

        return (
            <Dropdown
                placement="bottomRight"
                overlay={notificationList}
                onVisibleChange={this.handleVisibleChange}
                visible={visible}
                trigger={['click']}
            >
                <Menu mode="horizontal">
                    <Menu.Item key="notification">
                        <Badge count={notifications.length}>
                            <MailOutlined className="nav-icon mx-auto" type="bell" />
                        </Badge>
                    </Menu.Item>
                </Menu>
            </Dropdown>
        );
    }
}

const mapStateToProps = (state) => ({
    notifications: state.mail.notifications,
    inboxName: state.mail.inboxName
});

export default withRouter(
    connect(mapStateToProps, { ignoreAllNotification, ignoreOneNotification })(NavMail)
);
