import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { DesktopAppContext } from 'utils/socket';

function DesktopAppProvider({ children, token }) {
  const url = 'doapp://';
  useEffect(() => {
    // window.location.href = url;
    if (token) {
    }
  }, [token]);
  return (
    <DesktopAppContext.Provider value={url}>
      {children}
    </DesktopAppContext.Provider>
  );
}

const mapStateToProps = ({ auth }) => {
  const { token } = auth;
  return { token };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DesktopAppProvider);
