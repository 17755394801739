import {
  CREATE_LIST_SUCCESS,
  DELETE_LIST_SUCCESS,
  LIST_MESSAGE,
  LOAD_BY_PROJECT_LIST_SUCCESS,
  LOAD_LIST_SUCCESS,
  UPDATE_LIST_SUCCESS,
} from "redux/constants/manage-project/list.type";

const initialState = {
  data: [],
  loading: false,
  message: "",
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_LIST_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case CREATE_LIST_SUCCESS:
      return { ...state, data: [...state.data, action.payload] };
    case UPDATE_LIST_SUCCESS:
      const updated = state.data.map((l) =>
        l._id === action.payload._id ? action.payload : l
      );
      return { ...state, loading: false, data: updated };
    case DELETE_LIST_SUCCESS:
      const deleted = state.data.filter((l) => l._id !== action.payload._id);
      return { ...state, loading: false, data: deleted };

    case LOAD_BY_PROJECT_LIST_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case LIST_MESSAGE:
      return {
        ...state,
        message: action.message,
        loading: false,
      };
    default:
      return state;
  }
}

export default ListReducer;
