import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { getTimersActionSuccess } from 'redux/actions/timer';
import { GET_TIMERS } from 'redux/constants/timer';
import { getTimersAPI } from 'services/TimerService';

export function* getTimers() {
  yield takeEvery(GET_TIMERS, function* ({ payload }) {
    const { userId, email } = payload;
    const response = yield call(getTimersAPI, userId, email);
    yield put(getTimersActionSuccess(response.data.data));
  });
}

export default function* rootSaga() {
  yield all([fork(getTimers)]);
}
