import React, { useState } from 'react';

export const RouteContext = React.createContext();

export default function RouteProvider({ children }) {
  const [actualRoute, setActualRoute] = useState(null);

  const updateActualRoute = (value) => {
    setActualRoute(value);
  };

  return (
    <RouteContext.Provider value={{ actualRoute, updateActualRoute }}>
      {children}
    </RouteContext.Provider>
  );
}
