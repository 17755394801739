import {
    CREATE_SPRINT,
    CREATE_SPRINT_SUCCESS,
    DELETE_SPRINT,
    DELETE_SPRINT_SUCCESS,
    SPRINT_ERROR,
    SPRINT_MESSAGE,
    LOAD_BY_PROJECT_SPRINT,
    LOAD_BY_PROJECT_SPRINT_SUCCESS,
    LOAD_SPRINT,
    LOAD_SPRINT_SUCCESS,
    UPDATE_SPRINT,
    UPDATE_SPRINT_SUCCESS,
  } from "redux/constants/manage-project/sprint.type";
  
  export const loadSprint = () => {
    return {
      type: LOAD_SPRINT,
    };
  };
  
  export const loadSprintByProject = (projectID) => {
    return {
      type: LOAD_BY_PROJECT_SPRINT,
      payload: { projectID },
    };
  };
  
  export const createSprint = (sprint) => {
    console.log("sprint to create : ",sprint);
    
    return {
      type: CREATE_SPRINT,
      payload: { sprint },
    };
  };
  
  export const updateSprint = (sprintID, sprint) => {
    return {
      type: UPDATE_SPRINT,
      payload: {
        sprintID,
        sprint,
      },
    };
  };
  
  export const deleteSprint = (sprintID) => {
    return {
      type: DELETE_SPRINT,
      payload: sprintID,
    };
  };
  
  export const loadSprintSuccess = (sprints) => {
    return {
      type: LOAD_SPRINT_SUCCESS,
      payload: sprints,
    };
  };
  
  export const loadSprintByProjectSuccess = (data) => {
    return {
      type: LOAD_BY_PROJECT_SPRINT_SUCCESS,
      payload: data,
    };
  };
  export const deleteSprintSuccess = (deleted) => {
    return {
      type: DELETE_SPRINT_SUCCESS,
      payload: deleted,
    };
  };
  
  export const updateSprintSuccess = (updated) => {
    return {
      type: UPDATE_SPRINT_SUCCESS,
      payload: updated,
    };
  };
  
  export const createdSprintSuccess = (created) => {
    return {
      type: CREATE_SPRINT_SUCCESS,
      payload: created,
    };
  };
  
  export const SprintError = (msg) => {
    return {
      type: SPRINT_ERROR,
      payload: msg,
    };
  };
  
  export const showSprintMessage = (message) => {
    return {
      type: SPRINT_MESSAGE,
      message,
    };
  };
  