import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  createdTaskSuccess,
  deleteCurrentTaskSuccess,
  deleteTaskSuccess,
  dragTaskSuccess,
  loadTaskSuccess,
  setDescriptionSuccess,
  setMembersSuccess,
  setStoryPointSuccess,
  showTaskMessage,
  updateTaskSuccess,
} from "redux/actions/manage-project/task.action";
import {
  CREATE_TASK,
  DELETE_CURRENT_TASK,
  DELETE_TASK,
  DRAG_TASK,
  LOAD_TASK_BY_LIST,
  SET_LABELS,
  SET_MEMBERS,
  UPDATE_TASK,
  SET_DESCRIPTION,
  SET_STORYPOINT,
} from "redux/constants/manage-project/task.type";
import {
  created,
  deleted,
  loadByList,
  setLabelsReuqest,
  setMembersReuqest,
  setDescriptionReuqest,
  setStoryPointRequest,
  updated,
} from "services/TaskService";

export function* byList() {
  yield takeEvery(LOAD_TASK_BY_LIST, function* ({ payload }) {
    const { listID } = payload;
    try {
      const response = yield call(loadByList, listID);
      if (response.data.message) {
        yield put(showTaskMessage(response.data.message));
      } else {
        yield put(loadTaskSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showTaskMessage(err.response ? err.response.data : err));
    }
  });
}

export function* create() {
  yield takeEvery(CREATE_TASK, function* ({ payload }) {
    const { task } = payload;
    try {
      const response = yield call(created, task);
      if (response.data.message) {
        yield put(showTaskMessage(response.data.message));
      } else {
        yield put(
          createdTaskSuccess({
            task: response.data.data,
            idList: response.data.data.listId,
          })
        );
      }
    } catch (err) {
      yield put(showTaskMessage(err.response ? err.response.data : err));
    }
  });
}

export function* update() {
  yield takeEvery(UPDATE_TASK, function* ({ payload }) {
    const { taskID, task } = payload;
    try {
      const response = yield call(updated, taskID, task);
      if (response.data.message) {
        yield put(showTaskMessage(response.data.message));
      } else {
        yield put(updateTaskSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showTaskMessage(err.response ? err.response.data : err));
    }
  });
}

export function* setMembersSaga() {
  yield takeEvery(SET_MEMBERS, function* ({ payload }) {
    const { taskID, members } = payload;
    try {
      const response = yield call(setMembersReuqest, taskID, members);
      yield put(setMembersSuccess(response.data.data));
    } catch (err) {
      yield put(showTaskMessage(err.response ? err.response.data : err));
    }
  });
}

export function* setLabelsSaga() {
  yield takeEvery(SET_LABELS, function* ({ payload }) {
    const { taskID, labels } = payload;
    try {
      const response = yield call(setLabelsReuqest, taskID, labels);
      yield put(setMembersSuccess(response.data.data));
    } catch (err) {
      yield put(showTaskMessage(err.response ? err.response.data : err));
    }
  });
}

export function* setDescriptionSaga() {
  yield takeEvery(SET_DESCRIPTION, function* ({ payload }) {
    const { taskID, description } = payload;
    try {
      const response = yield call(setDescriptionReuqest, taskID, description);
      yield put(setDescriptionSuccess(response.data.data));
    } catch (err) {
      yield put(showTaskMessage(err.response ? err.response.data : err));
    }
  });
}

export function* setStoryPointSaga() {
  yield takeEvery(SET_STORYPOINT, function* ({ payload }) {
    const { taskID, storyPoint } = payload;
    try {
      const response = yield call(setStoryPointRequest, taskID, storyPoint);
      yield put(setStoryPointSuccess(response.data.data));
    } catch (err) {
      yield put(showTaskMessage(err.response ? err.response.data : err));
    }
  });
}

export function* deleteTask() {
  yield takeEvery(DELETE_TASK, function* ({ payload }) {
    try {
      const response = yield call(deleted, payload);
      if (response.data.message) {
        yield put(showTaskMessage(response.data.message));
      } else {
        yield put(deleteTaskSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showTaskMessage(err.response ? err.response.data : err));
    }
  });
}

export function* dragTask() {
  yield takeEvery(DRAG_TASK, function* ({ payload }) {
    try {
      yield put(dragTaskSuccess(payload));
    } catch (err) {
      yield put(showTaskMessage("Erreur drag"));
    }
  });
}

export function* deleteCurrentTask() {
  yield takeEvery(DELETE_CURRENT_TASK, function* ({ payload }) {
    try {
      yield put(deleteCurrentTaskSuccess());
    } catch (err) {
      yield put(showTaskMessage("Erreur drag"));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(byList),
    fork(create),
    fork(update),
    fork(deleteTask),
    fork(dragTask),
    fork(deleteCurrentTask),
    fork(setMembersSaga),
    fork(setLabelsSaga),
    fork(setDescriptionSaga),
    fork(setStoryPointSaga),
  ]);
}
