import {
  API_BASE_URL_CHAT_SOCKET,
  API_BASE_URL_TRACKING,
  API_TIMER_URL,
} from 'constants/ApiConstant';
import React from 'react';
import io from 'socket.io-client';

export const socket = io(API_BASE_URL_CHAT_SOCKET);
export const chatSocket = io(API_BASE_URL_CHAT_SOCKET);
// export const trackingSocket = io(API_BASE_URL_TRACKING);
export const timerSocket = io(API_TIMER_URL);
export const SocketContext = React.createContext();
export const SocketContextChat = React.createContext();
export const SocketContextTracking = React.createContext();
export const SocketTimerContext = React.createContext();
export const DesktopAppContext = React.createContext();
