import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  addCompanyConfigurationSuccess,
  companyConfigurationError,
  loadCompanyConfigurationSuccess,
  loadTrackingSuccess,
  showCompanyConfigurationMassage,
  updateCompanyConfigurationSuccess,
} from 'redux/actions/tracking/tracking';
import {
  ADD_COMPANY_CONFIGURATION,
  LOAD_COMPANY_CONFIGURATION,
  LOAD_TRACKING,
  UPDATE_COMPANY_CONFIGURATION,
} from 'redux/constants/tracking/tracking';
import {
  addConfiguration,
  editConfiguration,
  findByCompany,
  findByType,
} from 'services/TrackingService';

export function* loadConfiguration() {
  yield takeEvery(LOAD_COMPANY_CONFIGURATION, function* ({ payload }) {
    const { companyId } = payload;
    try {
      const response = yield call(findByCompany, companyId);
      console.log(response);
      if (response.data.message) {
        yield put(companyConfigurationError(response.data.message));
      } else {
        yield put(loadCompanyConfigurationSuccess(response.data.data));
      }
    } catch (err) {
      yield put(
        showCompanyConfigurationMassage(err.response ? err.response.data : err)
      );
    }
  });
}

export function* createConfiguration() {
  yield takeEvery(ADD_COMPANY_CONFIGURATION, function* ({ payload }) {
    const { data } = payload;
    try {
      const response = yield call(addConfiguration, data);
      if (response.data.message) {
        yield put(companyConfigurationError(response.data.message));
      } else {
        yield put(addCompanyConfigurationSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showCompanyConfigurationMassage(err));
    }
  });
}

export function* updateConfiguration() {
  yield takeEvery(UPDATE_COMPANY_CONFIGURATION, function* ({ payload }) {
    const { configId, data } = payload;
    try {
      const response = yield call(editConfiguration, configId, data);
      if (response.data.message) {
        yield put(companyConfigurationError(response.data.message));
      } else {
        yield put(updateCompanyConfigurationSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showCompanyConfigurationMassage(err));
    }
  });
}

export function* loadTracking() {
  yield takeEvery(LOAD_TRACKING, function* ({ payload }) {
    const { userId, type, projectId } = payload;
    try {
      const response = yield call(findByType, userId, type, projectId);
      console.log(response);
      if (response.data.message) {
        yield put(companyConfigurationError(response.data.message));
      } else {
        yield put(loadTrackingSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showCompanyConfigurationMassage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createConfiguration),
    fork(loadConfiguration),
    fork(updateConfiguration),
    fork(loadTracking),
  ]);
}
