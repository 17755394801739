import { all, takeEvery, put, fork, call, delay, select } from 'redux-saga/effects';
import {
  createdAgendaSuccess,
  deleteAgendaSuccess,
  loadAgendaByProjectSuccess,
  showAgendaMessage,
  updateAgendaSuccess,
  loadAgendaByUserSuccess
} from 'redux/actions/manage-project/agenda';
import {
  CREATE_AGENDA,
  DELETE_AGENDA,
  GET_BY_PROJECT_AGENDA,
  UPDATE_AGENDA,
} from 'redux/constants/manage-project/agenda.type';
import {
  created,
  deleted,
  loadByproject,
  loaded,
  updated,
  loadByUser
} from 'services/AgendaService';

export function* add() {
  yield takeEvery(CREATE_AGENDA, function* ({ payload }) {
    const { agenda } = payload;
    try {
      const response = yield call(created, agenda);
      if (response.data.message) {
        yield put(showAgendaMessage(response.data.message));
      } else {
        yield put(createdAgendaSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showAgendaMessage(err.response ? err.response.data : err));
    }
  });
}

export function* update() {
  yield takeEvery(UPDATE_AGENDA, function* ({ payload }) {
    const { agenda, agendaID } = payload;
    try {
      const response = yield call(updated, agendaID, agenda);
      if (response.data.message) {
        yield put(showAgendaMessage(response.data.message));
      } else {
        yield put(updateAgendaSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showAgendaMessage(err.response ? err.response.data : err));
    }
  });
}

export function* del() {
  yield takeEvery(DELETE_AGENDA, function* ({ payload }) {
    try {
      const response = yield call(deleted, payload);
      if (response.data.message) {
        yield put(showAgendaMessage(response.data.message));
      } else {
        yield put(deleteAgendaSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showAgendaMessage(err.response ? err.response.data : err));
    }
  });
}

export function* byProject() {
  yield takeEvery(GET_BY_PROJECT_AGENDA, function* ({ payload }) {
    const { projectID } = payload;
    try {
      const response = yield call(loadByproject, projectID);
      console.log(response);
      if (response.data.message) {
        yield put(showAgendaMessage(response.data.message));
      } else {
        yield put(loadAgendaByProjectSuccess(response.data.data));
      }
    } catch (err) {
      yield put(showAgendaMessage(err.response ? err.response.data : err));
    }
  });
}

function* byUser() {
  while (true) {
    try {
      const auth = yield select(state => state.auth.current);
      const current_date = new Date().toUTCString();
      if(auth) {
        const res = yield call(loadByUser, auth._id, auth.email, current_date);
        // Stockage du token d'accès dans sessionStorage
        const agendas = res.data.data;
        if(agendas.length > 0) {
          yield put(loadAgendaByUserSuccess(agendas));
        }
      }
      yield delay(300000);
    } catch (error) {
      console.log(error)
      yield delay(20000);
    }
  }
}

export default function* rootSaga() {
  yield all([fork(add), fork(update), fork(del), fork(byProject), fork(byUser)]);
}
