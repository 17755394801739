import {
  AGENDA_MESSAGE,
  CREATE_AGENDA_SUCCESS,
  DELETE_AGENDA_SUCCESS,
  GET_BY_PROJECT_AGENDA_SUCCESS,
  UPDATE_AGENDA_SUCCESS,
} from 'redux/constants/manage-project/agenda.type';
import {
  CHECKLIST_MESSAGE,
  CREATE_CHECKLIST_SUCCESS,
  DELETE_CHECKLIST_SUCCESS,
  LOAD_CHECKLIST_BY_TASK_SUCCESS,
  UPDATE_CHECKLIST_SUCCESS,
} from 'redux/constants/manage-project/checklist.type';

const initialState = [];

function ChecklistReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_CHECKLIST_BY_TASK_SUCCESS:
      return action.payload;
    case CREATE_CHECKLIST_SUCCESS:
      return [...state, action.payload];
    case UPDATE_CHECKLIST_SUCCESS:
      const updated = state.map((c) =>
        c._id === action.payload._id ? action.payload : c
      );
      return updated;
    case DELETE_CHECKLIST_SUCCESS:
      const deleted = state.filter((c) => c._id !== action.payload._id);
      return deleted;
    case CHECKLIST_MESSAGE:
      return {
        ...state,
        message: action.message,
        loading: false,
      };
    default:
      return state;
  }
}

export default ChecklistReducer;
