export const LOAD_LIST = "LOAD_LIST";
export const CREATE_LIST = "CREATE_LIST";
export const UPDATE_LIST = "UPDATE_LIST";
export const DELETE_LIST = "DELETE_LIST";
export const LIST_MESSAGE = "LIST_MESSAGE";
export const LIST_ERROR = "LIST_ERROR";
export const LOAD_BY_PROJECT_LIST = "LOAD_BY_PROJECT_LIST";

export const LOAD_LIST_SUCCESS = "LOAD_LIST_SUCCESS";
export const CREATE_LIST_SUCCESS = "CREATE_LIST_SUCCESS";
export const UPDATE_LIST_SUCCESS = "UPDATE_LIST_SUCCESS";
export const DELETE_LIST_SUCCESS = "DELETE_LIST_SUCCESS";
export const LOAD_BY_PROJECT_LIST_SUCCESS = "LOAD_BY_PROJECT_LIST_SUCCESS";
