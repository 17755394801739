import axios from 'axios';
import { API_BASE_URL } from 'constants/ApiConstant';

const url = API_BASE_URL + 'agenda/';

export const created = (data) => {
  return axios.post(`${url}`, data);
};

export const updated = (id, data) => {
  return axios.put(`${url}${id}`, data);
};

export const deleted = (id) => {
  return axios.delete(`${url}${id}`);
};

export const loaded = () => {
  return axios.get(`${url}`);
};

export const loadByproject = (id) => {
  return axios.get(`${url}project/${id}`);
};

export const loadByUser = (userId, email, current_date) => {
  return axios.get(`${url}user/${userId}/email/${email}/current_date/${current_date}`);
}
