export const API_BASE_URL_TRACKING = 'https://tracking-dev.goavana.com/';
export const API_TIMER_URL= 'https://doback-dev.goavana.com/'
export const API_BASE_URL_AUTH = 'https://auth-dev.goavana.com/auth/';

export const API_BASE_URL = 'https://doback-dev.goavana.com/api/v1/';
export const API_STATIC_URL = 'https://doback-dev.goavana.com/files/';

export const API_BASE_URL_CHAT = 'https://chat-dev.goavana.com/api/v1/workspace/';
export const API_BASE_URL_CHAT_SOCKET = 'https://chat-dev.goavana.com/';

export const API_BASE_URL_MAIL = 'https://do.mail.back-dev.goavana.com/';
export const API_BASE_KEY_MAIL = 'django-insecure-7^%l9_7-aj)66@^*dp)16548bou%w*3o!6q&67h9%2$2p8pslh'
