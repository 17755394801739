import {
    CREATE_SPRINT_SUCCESS,
    DELETE_SPRINT_SUCCESS,
    SPRINT_MESSAGE,
    LOAD_BY_PROJECT_SPRINT_SUCCESS,
    LOAD_SPRINT_SUCCESS,
    UPDATE_SPRINT_SUCCESS,
  } from "redux/constants/manage-project/sprint.type";
  
  const initialState = {
    data: [],
    loading: false,
    message: "",
  };
  
  function SprintReducer(state = initialState, action) {
    switch (action.type) {
      case LOAD_SPRINT_SUCCESS:
        return { ...state, loading: false, data: action.payload };
      case CREATE_SPRINT_SUCCESS:
        return { ...state, data: [action.payload,...state.data] };
      case UPDATE_SPRINT_SUCCESS:
        const updated = state.data.map((s) =>
          s._id === action.payload._id ? action.payload : s
        );
        return { ...state, loading: false, data: updated };
      case DELETE_SPRINT_SUCCESS:
        const deleted = state.data.filter((s) => s._id !== action.payload._id);
        return { ...state, loading: false, data: deleted };
      case LOAD_BY_PROJECT_SPRINT_SUCCESS:
        return { ...state, loading: false, data: action.payload };
      case SPRINT_MESSAGE:
        return {
          ...state,
          message: action.message,
          loading: false,
        };
      default:
        return state;
    }
  }
  
  export default SprintReducer;